<template>
<div class="login-container">
    <TourialLogo/>
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('login.title') }}</h3>
        <p>{{ $t('login.caption') }}</p>
    </div>

    <form>
        <vs-input block placeholder="Email" type="email" v-model="email" v-on:keyup.enter="loginEvent"/>
        <vs-input block type="password" placeholder="Password" v-model="password" v-on:keyup.enter="loginEvent"/>
    </form>
    <p style="width: 100%; cursor: pointer;" @click="$router.push({name: 'Dimenticata'})">
        {{ $t('login.forgotPassword') }}
    </p>

    <vs-button size="large" block class="bold" @click="loginEvent" :loading="loading">{{ $t('login.signIn').toUpperCase() }}</vs-button>

    <p class="center">
        {{ $t('login.dontHaveAccount') }}<br />
        <router-link to="/explore/welcome">{{ $t('login.signUp') }}</router-link>
    </p>



    <p class="center" v-if="enabledFeatures['signInWithGoogle'] !== false">
        {{ $t('login.signInWith') }}
    </p>

    <vs-button transparent size="large" :loading="googleLoading" @click="signInWithGoogle" v-if="enabledFeatures['signInWithGoogle'] !== false"><img src="../../assets/icons/google.png" /></vs-button>

    <Footer/>
</div>
</template>

    
<script>
import TourialLogo from '../TourialLogo.vue';
import { apiCall, hash, handleGoogleStatus } from '../../utils/ApiMiddleware';
import { GlobalEventEmitter } from '../../utils/GlobalEventEmitter';
import Footer from '../Footer.vue';
import { baseUri } from '../../utils/ApiMiddleware';
export default {
    name: 'login',
    props: {
        iEmail: String,
    },
    data(){
        return {
            email: '',
            password: '',
            loading: false,
            googleLoading: false,
        }
    },
    methods: {
        async signInWithGoogle() {
            this.googleLoading = true;

            setTimeout(() => {
                this.googleLoading = false;
                window.location.href = baseUri + '/auth/external?provider=google&is_app=' + JSON.stringify(this.$IS_APP) + (this.$IS_APP ? '&duration=15552000000000000' : '');
            }, 1000);
        },


        async loginEvent(){
            this.loading = true;
            await this.login();
            this.loading = false;
        },

        async login(){
            // if email field is empty, show an error message
            if(this.email == ''){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('login.messages.emailEmpty'),
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // if password field is empty show an error message
            if(this.password == ''){
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('login.messages.passwordEmpty'),
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // use apiCall to call the login api
            const response = await apiCall('POST', '/users/login', {
                email: this.email,
                password: hash(this.password),
                duration: this.$IS_APP ? 15552000000000000 : undefined, // it's in nanosecond, 180 days
            }, true);
            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if(response.status == 200){
                this.$vs.notification({
                    title: this.$t('login.title'),
                    text: this.$t('login.messages.success'),
                    color: 'success',
                    position: 'top-right'
                });
                GlobalEventEmitter.$emit('loadUserMeta');

                this.$router.push('/explore');
            } else if(response.status != 0 || response.original_status == 401) { // 0 is an already handled error, no needs to show another error message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('login.messages.error'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        }
    },
    mounted(){
        // read status parameter from querystring
        const status = this.$route.query.status;
        handleGoogleStatus(status);
    },
    components: { TourialLogo, Footer },
    computed: {
        enabledFeatures() {
            return this.$FEATURES.settings;
        },
    },
}
</script>

    
<style scoped>
.login-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

/* DESKTOP */
@media (min-width: 1024px) {
    .login-container {
        width: 350px;
    }
}

/* MOBILE */
@media (max-width: 600px) {
    .login-container {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px!important;
        height: unset!important;
        width: 100%!important;

    }
}
</style>
